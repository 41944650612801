.notification-template {
    border-radius: 15px;
    background-color: #dddddd;
}

.notification-template .notification-header {
    background-color: #eeefef;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    display: flex;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.notification-template .notification-header .notification-header-left {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
}

.notification-template .notification-header .notification-header-right {
    text-align: right;
    height: 100%;
}

.notification-icon {
    padding: 5px; 
    display: block;
    background-image: linear-gradient(#64fd7e, #02b620);
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.notification-app {
    color: #4d4d4d;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    margin: 0;
}

.notification-time {
    color: #4e4e4e;
    font-size: 13px;
    display: inline;
}

.notification-body {
    padding: 10px;
    padding-left: 25px;
    text-align: left;
}

.notification-title {
    font-size: 17px;
    font-weight: 500;
    margin: 0;
}

.notification-message {
    font-size: 14px;
    margin: 0;
}